import {
  Badge,
  Button,
  ButtonGroup,
  ButtonGroupButton,
  Drawer,
  Header
} from '@crowley/enterprise-react-component-library';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { MdFilterList, MdOutlineList, MdOutlineMap } from 'react-icons/md';
import { HOME_PAGE_URL, LIST_PAGE_URL } from '../utils/routes';
import { FilterContext } from './FilterContext';
import { FilterSidebar } from './FilterSidebar';
import { FilterTextInput } from './FilterTextInput';

export function ActionBar() {
  const { pathname } = useRouter();
  const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);
  const { activeToggleFilters, activeTextFilters, onTextFilterChangeValue } =
    useContext(FilterContext);

  const appliedToggleFiltersCount = Object.keys(activeToggleFilters).length;

  const onFilterSidebarClose = () => setFilterSidebarIsOpen(false);

  return (
    <>
      <Header title="Vessel Tracker" level={5} className="px-4 sm:px-8">
        <div className="flex flex-grow justify-between">
          <FilterTextInput
            filters={activeTextFilters}
            filterType="VesselName"
            searchTextValue={onTextFilterChangeValue}
            placeHolder="Vessel name"
          />
          <div className="sm:pb-0">
            <ButtonGroup>
              <Link href={HOME_PAGE_URL} passHref>
                <ButtonGroupButton as="a" active={pathname === HOME_PAGE_URL}>
                  <MdOutlineMap size={20} className="inline sm:hidden" />
                  <span className="hidden sm:block">Map</span>
                </ButtonGroupButton>
              </Link>
              <Link href={LIST_PAGE_URL} passHref>
                <ButtonGroupButton as="a" active={pathname === LIST_PAGE_URL}>
                  <MdOutlineList size={20} className="inline sm:hidden" />
                  <span className="hidden sm:block">List</span>
                </ButtonGroupButton>
              </Link>
            </ButtonGroup>
            <Button
              onClick={() => setFilterSidebarIsOpen(true)}
              data-testid="filter-sidebar-button"
              variant="primary"
            >
              <>
                {appliedToggleFiltersCount > 0 ? (
                  <Badge
                    showBackgroundColor={false}
                    variant="primary"
                    className="md:mr-2"
                  >
                    <span className="mt-[3px]">
                      {appliedToggleFiltersCount}
                    </span>
                  </Badge>
                ) : (
                  <MdFilterList size={20} className="inline sm:hidden" />
                )}
                <span className="hidden sm:block mt-[3px]">Filter</span>
              </>
            </Button>
          </div>
        </div>
      </Header>
      <Drawer isOpen={filterSidebarIsOpen} onClose={onFilterSidebarClose}>
        <FilterSidebar onFilterSidebarClose={onFilterSidebarClose} />
      </Drawer>
    </>
  );
}
