import { Typography } from '@crowley/enterprise-react-component-library';
import { Vessel } from './VesselContext';

export interface Props {
  vessels: Vessel[];
  filteredVessels: Vessel[];
}

export const VesselCount = ({ vessels, filteredVessels }: Props) => {
  return (
    <Typography
      variant="h100"
      className="text-silver-90"
    >{`${filteredVessels.length} out of ${vessels.length} Vessels`}</Typography>
  );
};
