import {
  Checkbox,
  CollapsibleContainer,
  Divider
} from '@crowley/enterprise-react-component-library';
import { Fragment, useContext, useMemo } from 'react';
import { groupVesselsByAttr } from '../utils/groupVesselsByAttr';
import { FilterBy, ToggleFilters } from '../utils/useFilterState';
import { VesselContext } from './VesselContext';

interface Props {
  toggleFilters: ToggleFilters;
  onCheckboxChange: (filterType: FilterBy, filterValue: string) => void;
}

interface AvailableFilters {
  label: string;
  id: FilterBy;
  options: Record<string, number>;
  collapsed: boolean;
}

export function FilterForm({ toggleFilters, onCheckboxChange }: Props) {
  const { vessels } = useContext(VesselContext);

  const allToggleFilters: AvailableFilters[] = useMemo(() => {
    return [
      {
        label: 'Vessel Group',
        id: 'VesselGroup',
        options: groupVesselsByAttr(vessels, 'VesselGroup'),
        collapsed: false
      },
      {
        label: 'Fleet',
        id: 'Fleet',
        options: groupVesselsByAttr(vessels, 'Fleet'),
        collapsed: false
      },
      {
        label: 'Last Port',
        id: 'PreviousPortName',
        options: groupVesselsByAttr(vessels, 'PreviousPortName'),
        collapsed: true
      },
      {
        label: 'Next Port',
        id: 'NextPort',
        options: groupVesselsByAttr(vessels, 'NextPort'),
        collapsed: true
      },
      {
        label: 'Vessel Owner',
        id: 'VesselOwner',
        options: groupVesselsByAttr(vessels, 'VesselOwner'),
        collapsed: true
      },
      {
        label: 'Status',
        id: 'StatusDetail',
        options: groupVesselsByAttr(vessels, 'StatusDetail'),
        collapsed: true
      }
    ];
  }, [vessels]);

  function translateFleetName(abbreviation: string): string | undefined {
    let fullFleetName;
    if (abbreviation === 'CPS') {
      fullFleetName = 'Crowley Petroleum Services';
    } else if (abbreviation === 'GSM') {
      fullFleetName = 'Global Ship Management';
    } else if (abbreviation === 'CMS') {
      fullFleetName = 'Crowley Marine Services';
    } else if (abbreviation === 'CFS') {
      fullFleetName = 'Crowley Fuel Services';
    } else {
      fullFleetName = undefined;
    }
    return fullFleetName;
  }

  return (
    <div className="px-6">
      {allToggleFilters.map(({ label, id, options, collapsed }) => {
        return (
          <Fragment key={label}>
            <div className="px-6">
              <CollapsibleContainer heading={label} startOpen={!collapsed}>
                <div className="grid grid-cols-2">
                  {Object.keys(options).map((option) => {
                    const filterType = id as FilterBy;
                    return (
                      <Checkbox
                        key={option}
                        label={option}
                        name={`filter-${option}`}
                        tooltip={
                          filterType === 'Fleet'
                            ? translateFleetName(option)
                            : undefined
                        }
                        checked={
                          toggleFilters[filterType]?.has(option) ?? false
                        }
                        onChange={() => onCheckboxChange(filterType, option)}
                      />
                    );
                  })}
                </div>
              </CollapsibleContainer>
            </div>
            <Divider />
          </Fragment>
        );
      })}
    </div>
  );
}
