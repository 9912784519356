import { Badge, BadgeProps } from '@crowley/enterprise-react-component-library';
import { MdCircle } from 'react-icons/md';
import { VesselStatus } from './VesselContext';

interface StatusBadgeProps {
  status: VesselStatus;
  statusDetail?: string;
}

export function StatusBadge({ status, statusDetail = '' }: StatusBadgeProps) {
  let variant: BadgeProps['variant'] = 'primary';

  if (status === VesselStatus.Moving) {
    variant = 'positive';
  } else if (status === VesselStatus.Stopped) {
    variant = 'warning';
  } else if (
    status === VesselStatus.OutOfService ||
    status === VesselStatus.NA
  ) {
    variant = 'negative';
  }

  return (
    <Badge variant={variant}>
      <MdCircle size={6} className="inline mr-[10px]" />
      {statusDetail}
    </Badge>
  );
}
