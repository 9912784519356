import { DateTime } from 'luxon';

interface FormattedDateTime {
  formattedDate: string;
  formattedTime: string;
  formattedZone: string;
}

interface FormattedBrowserDateTime {
  formattedUTCTime: string;
  formattedUTCDate: string;
  formattedUTCZone: string;
}

// Commented for future implementation
/* interface FormattedVesselDateTime {
  formattedVesselTime: string;
  formattedVesselDate: string;
  formattedVesselZone: string;
} */

export function formatDateTime(
  dateTime?: number | string | null
): FormattedDateTime {
  const d = dateTime && DateTime.fromMillis(Number(dateTime));
  if (!d) return { formattedTime: '', formattedDate: '', formattedZone: '' };
  if (!d.isValid) {
    console.error(d, 'INVALID DATE');
    return { formattedTime: '', formattedDate: '', formattedZone: '' };
  }
  return {
    formattedTime: d.toLocaleString(DateTime.TIME_24_SIMPLE),
    formattedDate: d.toLocaleString(DateTime.DATE_SHORT),
    formattedZone: d.offsetNameShort
  };
}

export function formatISODate(dateTime?: string | null) {
  if (!dateTime)
    return { formattedTime: '', formattedDate: '', formattedZone: '' };
  const d = DateTime.fromISO(dateTime);
  if (!d.isValid) {
    console.error(d, 'INVALID DATE');
    return { formattedTime: '', formattedDate: '', formattedZone: '' };
  }
  return {
    formattedTime: d.toLocaleString(DateTime.TIME_24_SIMPLE),
    formattedDate: d.toLocaleString(DateTime.DATE_SHORT),
    formattedZone: d.offsetNameShort
  };
}

export function convertToUTC(
  dateTime?: number | string | null
): FormattedBrowserDateTime {
  const d = dateTime && DateTime.fromMillis(Number(dateTime)).toUTC();
  if (!d)
    return { formattedUTCTime: '', formattedUTCDate: '', formattedUTCZone: '' };
  if (!d.isValid) {
    console.error(d, 'INVALID DATE');
    return { formattedUTCTime: '', formattedUTCDate: '', formattedUTCZone: '' };
  }
  return {
    formattedUTCTime: d.toLocaleString(DateTime.TIME_24_SIMPLE),
    formattedUTCDate: d.toLocaleString(DateTime.DATE_SHORT),
    formattedUTCZone: d.offsetNameShort
  };
}

// Commented code for the future implementation of the time convertion to the vessel timezone, once we find a way to eiter get the timezone by latitude and longitude or

/* export function formatVesselDateTime(
  vesselLatitude: number,
  vesselLongitude: number,
  dateTime?: number
): FormattedVesselDateTime {
  const timeZone = 'zone';
  console.log(timeZone);
  console.log(vesselLatitude, vesselLongitude);
  const d = dateTime && DateTime.fromMillis(Number(dateTime)).setZone(timeZone);
  if (!d || !timeZone)
    return {
      formattedVesselTime: '',
      formattedVesselDate: '',
      formattedVesselZone: ''
    };
  return {
    formattedVesselTime: d.toLocaleString(DateTime.TIME_24_SIMPLE),
    formattedVesselDate: d.toLocaleString(DateTime.DATE_SHORT),
    formattedVesselZone: d.offsetNameShort
  };
} */
