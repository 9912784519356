import { NOT_AVAILABLE_TEXT } from '../utils/textCopy';
import { formatDateTime, convertToUTC } from '../utils/formatDateTime';
import { StackedText } from './StackedText';
import { Tooltip } from '@crowley/enterprise-react-component-library/build/components/Tooltip/Tooltip';
import { MdInfoOutline } from 'react-icons/md';

// latitud and longitude are commented since we have not implemented the time conversion to the vessel timezone yet.
interface DateDisplayProps {
  /* latitude: number;
  longitude: number; */
  date?: number;
}

export function DateDisplay({ date }: DateDisplayProps) {
  if (date) {
    const { formattedDate, formattedTime } = formatDateTime(date);
    const { formattedUTCDate, formattedUTCTime, formattedUTCZone } =
      convertToUTC(date);
    //commented since we have not implemented the time conversion to the vessel timezone yet
    /* const { formattedVesselTime, formattedVesselDate, formattedVesselZone } =
      formatVesselDateTime(latitude, longitude, date); */
    return (
      <div className="flex flex-row items-end">
        <StackedText title={formattedTime} subTitle={formattedDate} />
        <Tooltip
          tooltipText={
            formattedUTCTime +
            ' ' +
            formattedUTCDate +
            ' (' +
            formattedUTCZone +
            ')'
          }
          placement="bottom"
        >
          <MdInfoOutline size={16} className="text-silver-40 mb-1 ml-2" />
        </Tooltip>
      </div>
    );
  }

  return <>{NOT_AVAILABLE_TEXT}</>;
}
