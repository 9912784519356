import { ComponentType } from 'react';
import { Loading } from './Loading';
import { useOktaAuth } from './OktaContext';

export function withAuth(WrappedComponent: ComponentType<unknown>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  function WithAuh() {
    const { authState } = useOktaAuth();

    if (authState?.isAuthenticated) {
      return <WrappedComponent />;
    }

    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <Loading />
      </div>
    );
  }

  WithAuh.displayName = `WithAuth(${displayName})`;

  return WithAuh;
}
