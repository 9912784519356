import {
  Sidebar,
  SidebarHeader,
  Button,
  SidebarContent,
  SidebarFooter,
  Badge,
  Typography,
  Link
} from '@crowley/enterprise-react-component-library';
import { useContext } from 'react';
import { useFilterState } from '../utils/useFilterState';
import { FilterForm } from './FilterForm';
import { FilterContext } from './FilterContext';

interface Props {
  onFilterSidebarClose: () => void;
}

export function FilterSidebar({ onFilterSidebarClose }: Props) {
  const { activeToggleFilters, applyMultipleToggleFilters } =
    useContext(FilterContext);

  const {
    activeToggleFilters: selectedToggleFilters,
    onFilterChangeSingleValue,
    resetAllFilters: resetSelectedFilters
  } = useFilterState({
    initialToggleFilterState: activeToggleFilters,
    persist: false
  });

  const onApplyClick = () => {
    applyMultipleToggleFilters(selectedToggleFilters);
    onFilterSidebarClose();
  };

  const onClearClick = () => {
    resetSelectedFilters();
  };

  return (
    <Sidebar>
      <SidebarHeader className="flex justify-between items-center">
        <Typography variant="h600" className="ml-3">
          <span className="mr-4" data-testid="filter-sidebar-header">
            Filters
          </span>
          <Badge
            variant="primary"
            size="large"
            showBackgroundColor={Object.keys(selectedToggleFilters).length > 0}
          >
            {Object.keys(selectedToggleFilters).length}
          </Badge>
        </Typography>
        <Button variant="tertiary" onClick={onFilterSidebarClose}>
          Cancel
        </Button>
      </SidebarHeader>
      <SidebarContent>
        <FilterForm
          toggleFilters={selectedToggleFilters}
          onCheckboxChange={onFilterChangeSingleValue}
        />
      </SidebarContent>
      <SidebarFooter className="justify-between">
        <Link
          onClick={onClearClick}
          disabled={!Object.keys(selectedToggleFilters).length}
          data-testid="filter-sidebar-clear-button"
        >
          Clear All Filters
        </Link>
        <Button
          variant="primary"
          onClick={onApplyClick}
          data-testid="filter-sidebar-apply-button"
        >
          Apply
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
}
