import { Vessel } from '../components/VesselContext';
import { FilterBy } from './useFilterState';

/**
 *
 * @param vessels Array of vessels
 * @param attr key in the vessels object
 * @returns Object with vessels grouped by attr and their count
 */
export function groupVesselsByAttr(
  vessels: Vessel[],
  attr: FilterBy
): Record<string, number> {
  return vessels.reduce((acc, curr) => {
    const key = curr[attr];
    if (key && acc[key]) {
      return {
        ...acc,
        [key]: acc[key] + 1
      };
    } else if (key) {
      return {
        ...acc,
        [key]: 1
      };
    }

    return acc;
  }, {} as Record<string, number>);
}
