import { Typography } from '@crowley/enterprise-react-component-library';
import { NOT_AVAILABLE_TEXT } from '../utils/textCopy';

interface StackedTextProps {
  title?: string;
  subTitle?: string;
}

export function StackedText({ title, subTitle }: StackedTextProps) {
  return (
    <div className="flex flex-col">
      <Typography variant="body-small" className="text-silver-100">
        {title ?? NOT_AVAILABLE_TEXT}
      </Typography>
      <Typography variant="body-small" className="text-silver-90">
        {subTitle ?? NOT_AVAILABLE_TEXT}
      </Typography>
    </div>
  );
}
